/* HTML: <div class="hexagon"></div> */
div.hexagon {
    --b: 4px; /* adjust to control the border  */
    width: 100%; /* adjust to control the size  */
    aspect-ratio: cos(30deg);
    clip-path: 
      polygon(50% 0,-50% 50%,50% 100%,150% 50%,50% 0,
      50% var(--b),
      calc(100% - var(--b)*sin(60deg)) calc(25% + var(--b)*cos(60deg)),
      calc(100% - var(--b)*sin(60deg)) calc(75% - var(--b)*cos(60deg)),
      50% calc(100% - var(--b)),
      calc(var(--b)*sin(60deg)) calc(75% - var(--b)*cos(60deg)),
      calc(var(--b)*sin(60deg)) calc(25% + var(--b)*cos(60deg)),
      50% var(--b));
    background: #000000;
}

.artifact {
    background: #ff8800 !important;
}

/* HTML: <img class="hexagon" src="" alt=""> */
img.hexagon {
    box-sizing: border-box;
    width: 100%;
    margin-bottom: -4px; /* TODO: Fix this in less hacky way */
    clip-path: polygon(-50% 50%,50% 100%,150% 50%,50% 0);
    background: #000000;
    color: #FFFFFF;
}
